$remoteHeight: 150px;
$userModeHeight: 450px;

$icomoon-font-family: "Daisy" !default;
$icomoon-font-path: "fonts" !default;

$i-mini-fill-path1: "\e900";
$i-mini-fill-path2: "\e901";
$i-mini-path1: "\e904";
$i-mini-path2: "\e905";
$i-mini-path3: "\e906";
$i-pause: "\e908";
$i-play: "\e909";
$i-remote-settings-fill: "\e90a";
$i-remote-settings: "\e90b";

$palette-black: black;
$palette-white: white;
$palette-grey-very-dark: #333;
$palette-grey-dark: #666;
$palette-grey: #999;
$palette-grey-light: #CCC;
$palette-red: #F00;
$palette-orange: #F90;
$palette-yellow: #FF0;
$palette-green: #0F0;
$palette-cyan: #0FF;
$palette-blue: #00F;
$palette-magenta: #F0F;

$palette-danger: #C00;;
$palette-error: $palette-danger;
$palette-standard: #06C;
$palette-warn: #BB0;
$palette-success: #0A0;
