@tailwind base;
@tailwind components;
@tailwind utilities;

@import './ui/icons';
@import "../lib/variables.scss";

@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src:
    local(".SFNSText-Light"),
    local(".HelveticaNeueDeskInterface-Light"),
    local(".LucidaGrandeUI"),
    local("Ubuntu Light"),
    local("Segoe UI Light"),
    local("Roboto-Light"),
    local("DroidSans"),
    local("Tahoma");
}

html {
  height: 100%;
}

body {
  @apply overflow-hidden w-screen;
  -webkit-font-smoothing: antialiased;
  font: 16px "Lucida Sans", system;
  letter-spacing: -0.025em;

  --palette-black: $palette-black;
  --palette-white: $palette-white;
  --palette-red: $palette-red;
  --palette-orange: $palette-orange;
  --palette-yellow: $palette-yellow;
  --palette-green: $palette-green;
  --palette-cyan: $palette-cyan;
  --palette-blue: $palette-blue;
  --palette-magenta: $palette-magenta;
  
  --palette-danger: $palette-danger;
  --palette-error: $palette-error;
  --palette-standard: $palette-standard;
  --palette-warn: $palette-warn;
  --palette-success: $palette-success;
}

$colors: (
  "black": $palette-black,
  "white": $palette-white,
  "grey-very-dark": $palette-grey-very-dark,
  "grey-dark": $palette-grey-dark,
  "grey": $palette-grey,
  "grey-light": $palette-grey-light,
  "red": $palette-red,
  "orange": $palette-orange,
  "yellow": $palette-yellow,
  "green": $palette-green,
  "cyan": $palette-cyan,
  "blue": $palette-blue,
  "magenta": $palette-magenta,
  "danger": $palette-danger,
  "error": $palette-error,
  "standard": $palette-standard,
  "warn": $palette-warn,
  "success": $palette-success,
);

@each $color, $name in $colors {
  .bg-#{$color} { background-color: #{$name}; }
  .color-#{$color} { color: #{$name}; }
}

h1, h2, h3, h4, h5, h6 {
  @apply w-full;
}

h1 { font-size: 3rem; margin-bottom: 1rem; }
h2 { font-size: 2rem;  margin-bottom: 1rem; }
h3 { font-size: 1.6rem; font-weight: bold; margin-bottom: 1rem; }
h4 { font-size: 1.2rem; font-weight: bold; margin-bottom: 1rem;  }
h5 { font-size: 1rem; }
h6 { font-size: .8rem; }
