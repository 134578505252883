$icomoon-font-family: "Daisy" !default;
$icomoon-font-path: "/assets/fonts" !default;

$i-play: "\e900";
$i-pause: "\e901";
$i-mini: "\e902";
$i-mini-fill: "\e903";
$i-remote-settings: "\e904";
$i-remote-settings-fill: "\e905";
$i-user: "\e906";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rzn1j4') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rzn1j4') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rzn1j4##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.i {
    &-play:before { content: $i-play }
    &-pause:before { content: $i-pause }
    &-mini:before { content: $i-mini; }
    &-mini-fill:before { content: $i-mini-fill; }
    &-remote-settings-fill:before { content: $i-remote-settings-fill }
    &-remote-settings:before { content: $i-remote-settings }
    &-user:before { content: $i-user }
  }
}
