@import "lib/variables";

.thumb {
  @apply h-[80px] w-full rounded-lg overflow-hidden;
  aspect-ratio: 1.77;

  :global {
    .display {
      @apply relative;
      width: 800%;
      height: calc( 90px * 7);
      transform: scale(0.125);
      transform-origin: top left;
      // aspect-ratio: 1.85 / 1;
    }
  }
}

.unit {
  @apply ml-1 opacity-50;
  font-size: .9em;
}

.symbol {
  @apply inline-block font-bold opacity-50;
  width: 1ex;
  height: 1em;
  font-size: 1.5em;
}

.settingsTable {
  @apply mx-auto border-spacing-0 w-full rounded-lg overflow-hidden;
  thead {
    background-color: $palette-grey-light;
  }
  tr {
    @apply table w-full;
  }
  tbody {
    @apply block;
    tr {
      @apply cursor-pointer;
      transition: background-color 0.125s ease-in;
      &:hover {
        background-color: transparentize($palette-yellow, 0.95);
      }
      &.active {
        background-color: transparentize($palette-blue, 0.90);
      }
    }
    @apply overflow-y-auto;
    max-height: calc( 100vh - 190px );
  }
  tr:nth-of-type(even) {
    background-color: $palette-grey-light;
  }
  td, th {
    @apply text-center;
    border-bottom: 0.5px solid transparentize($palette-white, .65);
    // border: 0.5px solid red;
    width: calc( 100% / 17 );
    min-width: 40px;
    line-height: 1.3em;

    &.name {
      @apply relative text-left w-1/5 min-w-[150px] font-bold;
    }

    &.thumbnail {
      @apply relative;
      width: 15%;
      min-width: 140px;
      iframe {
        @apply rounded-lg w-full;
        aspect-ratio: 1.77;
      }
      .spinner {
        @apply absolute flex size-full p-4 mx-auto aspect-square;
      }
    }
    &.actions {
      @apply text-right pr-4 whitespace-nowrap;
      width: calc( ( 100% / 17 ) * 2 );
      button {
        @apply ml-3;
        height: 1em;
      }
      .update {
        font-size: 30px;
        &:hover {
          color: $palette-success;
        }
      }
      .delete {
        font-size: 20px;
        &:hover {
          color: $palette-danger;
        }
      }
    }
  }

  svg {
    @apply h-auto w-5/6 mx-auto;
    aspect-ratio: 1.33;
  }
}

