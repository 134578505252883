@import '../lib/variables.scss';

.page {
  @apply overflow-hidden flex flex-col items-center justify-center h-screen p-4;
  background-image:
    radial-gradient(closest-corner at 10px 10px, #FE6F 15px, #D8000011 90px, #FF990000 300px),
    linear-gradient(hsl(220 100% 50%), #4E99D9);
  color: $palette-white;
  will-change: transform;
}

.logo {
  @apply w-[20vw] mb-4 blur-0 mx-auto;
  fill: white;
  :global(#Daisy_2) {
    fill: #F90;
  }
}

.card {
  @apply flex flex-col items-stretch text-center rounded-xl p-8 w-1/3 min-w-[375px] backdrop-blur opacity-0;
  background-color: transparentize($palette-white, 0.9);
  transition: opacity 0.25s 0.25s ease-in;
  &:global(.slideIn) {
    @apply opacity-100;
  }
  box-shadow:
    inset 1px 1px 0px #FFF4,
    inset -1px -1px 0px #0004;
}

@mixin child($count) {
  $staggerDelay: .25;
  @for $n from 0 to $count {
    &:nth-child(#{$n}) {
      transition-delay: #{$n * $staggerDelay}s;
    }
  }
}

:global {
  .slider {
    .step {
      opacity: 0;
      transform: translateY(-200px);
      transition:
        opacity 2s ease-in,
        transform 1s cubic-bezier(0, 0.5, 0.5, 1);
    }
    &.slideIn .step {
      opacity: 1;
      transform: translateY(0px);
      @include child(10);
    }
  }
}
