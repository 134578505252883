@import '../lib/variables.scss';

.remote {
  @apply overflow-hidden w-screen h-screen;
  max-height: $remoteHeight;
  padding: 15px;
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  color: #888;
  &:global(.popup) {
    background-color: #111;
  }

  &:global(.userMode) {
    max-height: $userModeHeight;
    :global(.user-panel) {
      top: 0;
      opacity: 1;
    }
    .page {
      opacity: 0;
    }
  }
}

.page {
  @apply relative z-0 flex-1 flex flex-col items-stretch;
  transition: opacity 0.25s linear;
}

.topButtons {
  @apply absolute z-20 flex right-0 flex-nowrap top-[-13px] justify-center items-center flex-row-reverse;
  button {
    @apply my-0 bg-black;
  }
}

.statusDisplay {
  @apply absolute -top-[14px] h-[24px] rounded-r overflow-hidden;
  background: #aeaeae;
}

.standardButton {
  @apply px-5 py-0;
  border-radius: 0;
  border-right: 0;
  height: 25px;
  width: auto;

  &:first-child {
    border: 1px solid #999;
    border-radius: 0 5px 5px 0;
  }
}
.playButton {
  position: relative;
  border: 1px solid #999;
  margin: 0px -10px;
}

.popButton {
  position: absolute;
  right: 0;
  top: -13px;
  cursor: pointer;
  padding: 5px;
  font-size: 20px;
  margin: -5px;
  &:hover {
    color: #999;
  }
}

.shape {
  @apply cursor-pointer;
  transition: transform 0.25s cubic-bezier(0.12, 3.04, 0.63, -0.09);
  &:hover {
    transform: scale(1.2);
  }
}

.swatches {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  margin: 0 10px;
}

.shapes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Helvetica;

  .shape {
    min-height: 29px;
    display: block;
    line-height: 1ex;
    overflow: hidden;
    font-size: 50px;
    color: #666;
    margin: 0px 0;

    &[data-option=diamond] {
      font-size: 40px;
    }    
  }
}


.sliders {
  @apply flex-wrap items-center justify-between text-xs;
  flex: 5;
  label {
    @apply text-sm flex-nowrap font-bold mb-3;
  }
}
