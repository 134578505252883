.tabs {
  @apply flex rounded justify-between overflow-hidden absolute z-10 left-1/2 -top-3;
  transform: translateX(-50%);
  background-color: #444;
}

.tab {
  @apply flex text-center cursor-pointer;
  font-size: 0.8125rem;
  line-height: 1em;
  color: white;
  margin: 0 .5px;
  padding: 10px 40px;
  text-decoration: none;
  font-weight: 600;
  -moz-user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  box-shadow: inset 0px 1px 0px 0px #FFF3;
  transition: background-color 75ms 0s ease-in;
  .light & {
    box-shadow-color: #c2c2c2b4;
  }
  background-color: #666;
  &:hover {
    background-color: #6b6b6b;
  }
  &:active {
    background-color: #888888;
  }
  &:global(.active) {
    color: #444;
    background-color: #AAA;
    &:hover {
      background-color: #BBB;
    }
    &:active {
      background-color: #CCC;
    }
  }
  &:global(.small) {
    padding: 5px 20px;
  }
}

.tabPanels {
  @apply grid grid-cols-1 items-center relative z-0 content-stretch;
  flex: 5;
  border-width: 1px;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  padding: 15px 0;
  margin-top: 10px;

  &.dark {
    background-color: #ffffff0C;
    border-color:  #303030;
  }

  &.light {
    background-color: #00000015;
    border-color:  #CCC;
  }
}

.tabPanel {
  @apply flex-wrap justify-center content-start h-full p-2;
  display: none;
  &:global(.active) {
    display: flex;
  }
}

