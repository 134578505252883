@import "lib/variables";

:global(.user-panel) {
  @apply
    absolute
    h-full
    w-full
    p-4
    pb-0
    flex
    flex-col
    justify-center
    opacity-0
    left-0
    z-10
    overflow-hidden
  ;
  top: 105%;
  background-color: #FFFFFFE9;
  color: #333;
  transition:
    top 0.125s linear,
    opacity 0.25s linear;
  backdrop-filter: blur(3px);

  :global(.userMode) & {
    transition-duration: 0.75s;
    transition-timing-function: cubic-bezier(0, 1, 0, 1);
  }
}

.close {
  @apply
    absolute
    right-0
    top-0
    mr-2
    mt-3
    z-20
    bg-neutral-700
    hover:bg-neutral-600
    text-neutral-300
    hover:text-white
  ;
}

.tabContent > div {
  @apply grid grid-cols-6 justify-center;
}

[data-option=logout] {
  background-color: darken($palette-success, 5);
  &:hover {
    background-color: darken($palette-success, 2.5);
  }
}

.panel {
  @apply p-8 pb-2;
}

.form {
  @apply flex flex-col w-3/5;
}

.alert {
  @apply px-2 py-4 mb-2;
}
