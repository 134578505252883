@import '../lib/variables.scss';

.toolbar {
  @apply w-full;
  height: $remoteHeight;
  background-color: rgba(16,16,16,.85);
  backdrop-filter: blur(3px);
  border: none;
  border-top: 1px solid #444;
  position: absolute;
  bottom: 0px;
  box-sizing: border-box;
  transition:
    transform .25s ease-out,
    height .25s ease-out,
    opacity .25s ease-out;

  &:global(.hidden) {
    display: unset;
    transition-duration: .75s;
    transform: translateY(100%);
    opacity: 0;
  }

  &:global(.remoteMode) {
    transition-delay: 0s;
    transition-duration: .25s;
    transform: translateY(100%);
    opacity: 0;
  }

  &:global(.userMode) {
    height: $userModeHeight;
  }
}
