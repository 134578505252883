@import "lib/variables.scss";

.formScalers {
  @apply my-2 overflow-hidden;

  &:global(.xs) { @apply px-2 py-1 text-xs; }
  &:global(.sm) { @apply px-3 py-1 text-sm; }
  &:global(.md) { @apply px-4 py-2 text-base  }
  &:global(.lg) { @apply px-6 py-2 text-xl; }
}

@mixin buttonColors($color) {  
  background-color: $color;
  &:hover {
    background-color: lighten($color, 5);
  }
  &:focus {
    outline: 3px solid transparentize($color, 0.5);
  }
}

.button {
  @extend .formScalers;
  @apply
    flex
    flex-1
    items-center
    justify-center
    text-center
    cursor-pointer
    box-border
    text-white
    font-bold
    blur-0
    overflow-visible
  ;

  &:global(.error) { @include buttonColors($palette-error); }
  &:global(.warn) { @include buttonColors($palette-warn); }
  &:global(.standard) { @include buttonColors($palette-standard); }
  &:global(.success) { @include buttonColors($palette-success); }
  &:global(.dark) { @include buttonColors($palette-grey-dark); }

  .spinner {
    @apply absolute right-0 mr-2;
  }

  :global(.tip) {
    @apply absolute -top-7 rounded text-sm px-2 opacity-0;
    background-color: $palette-grey-light;
    border: 1px solid $palette-grey;
    color: $palette-grey-dark;
    transition: opacity 0.125s 0s ease-in;
  }

  &:hover :global(.tip) {
    @apply opacity-100;
    transition-delay: 1s;
  }

  transition: background-color 0.1s ease-in;

  border: 1px solid #9999;
  box-shadow: 
    inset 1px 1px 1px #FFF5,
    inset -1px -1px 1px #0004;

  &:active {
    box-shadow: 
      inset 1px 1px 1px #0004,
      inset -1px -1px 1px #FFF4;
  }

  &:disabled, &:disabled:hover {
    @apply shadow-none opacity-75 brightness-75 saturate-75;
  }
}

.textfield {
  @extend .formScalers;
  @apply
  text-neutral-900
  border-neutral-400
    border-2
  ;

  &:focus-visible {
    outline-width: 0px;
    border-color: $palette-standard;
  }

  &:global(.error) {
    border-color: $palette-danger;
  }
}

.label {
  @apply mr-4;
  &:global(.xs) { @apply text-xs; }
  &:global(.sm) { @apply text-sm; }
  &:global(.md) { @apply text-base  }
  &:global(.lg) { @apply text-xl; }
}

.textGroup {
  @apply flex mt-2;
}

@mixin alertColors($color) {  
  color: desaturate(darken($color, 20), 20);
  border-color: desaturate(darken($color, 0), 65);
  background-color: transparentize($color, 0.9);
}

.alert {
  @extend .formScalers;
  @apply border-2 my-0;

  transition:
    background-color 0.25s ease-in,
    border-color 0.25s ease-in;

  &:global(.error) {
    @include alertColors($palette-error);
  }

  &:global(.warn) {
    @include alertColors($palette-warn);
  }

  &:global(.success) {
    @include alertColors($palette-success);
  }

  &:global(.standard) {
    @include alertColors($palette-standard);
  }

  &:global(.persist) {
    @include alertColors(transparent);
  }
}

.editField {
  @apply flex items-center absolute w-full h-full top-0 left-0 pl-2 cursor-pointer;

  .spinner {
    @apply absolute top-0 right-0;
    margin-top: 8px;
  }

  .textfield {
    @apply absolute mx-0 my-2 top-0 left-0 right-0 bottom-0;
    padding-left: 5px;
  }
  .editCloseIcon {
    @apply absolute top-0 right-0 m-[4px] p-1 text-black hover:text-blue-700 font-bold;
    transform: scaleX(-1);
    font-size: 22px;
  }
  &:global(.editMode) {
    @apply cursor-text;
    .editIcon {
      font-size: 20px;
    }
  }
}
